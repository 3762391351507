<template>
  <Loading v-if="isLoading"></Loading>
  <main v-else>
    <div @click="$router.go(-1)" class="flex cursor-pointer items-center">
      <div class="p-2 border border-opacity-25 rounded-md border-blue-500">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <div class="ml-2 text-sm text-black">Back</div>
    </div>
    New Loan
    <form @submit.prevent="updateLoan">
      <div class="grid grid-cols-2 gap-5 mt-10">
        <div class="flex flex-col pt-4">
          <label for="email" class="text-xs">Terminal ID *</label>
          <input
            type="text"
            required
            v-model="payload.terminal_id"
            class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          />
        </div>

        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Merchant ID</label>
          <div>
            <div
              @click.stop="businessDropdown = true"
              class="shadow cursor-pointer appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
            >
              {{ businessName || "Select Terminal" }}
              <!-- {{business.name ? business._id === payload.business_id : ""}} -->
            </div>
            <div
              v-if="businessDropdown"
              class="max-h-64 h-auto max-w-sm w-full overflow-y-auto absolute bg-white mt-1 shadow border p-2 rounded-md"
            >
              <input
                required
                @click.stop
                type="text"
                v-model="search"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
              <div
                v-click-outside="hide"
                class="mt-2"
                v-for="business in filterBusiness"
                :key="business._id"
              >
                <div @click="send(business)">
                  {{ business.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Loan Amount:</label>
          <input
            required
            type="number"
            v-model="payload.loan_amount"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex flex-col pt-4">
          <label for="password" class="text-xs">Repayment Amount</label>
          <input
            required
            type="text"
            v-model="payload.repayment_amount"
            class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
      <button
        type="submit"
        class="block uppercase shadow bg-blue-600 mt-10 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
      >
        Update
      </button>
    </form>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Loading from "./general-components/Loading.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      businessDropdown: false,
      isLoading: false,
      terminalProviders: [],
      banks: [],
      businesses: [],
      search: "",
      payload: {
        terminal_id: "",
        loan_amount: "",
        business_id: "",
        repayment_amount: "",
      },
    };
  },
  computed: {
    Loading,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    businessName() {
      let existingName = this.businesses.find(
        (business) => business.business_id === this.payload.business_id
      );
      if (existingName) {
        return existingName.name;
      }
    },
    filterBusiness() {
      return this.businesses.filter((business) =>
        business.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions([
      "UPDATE_LOAN",
      "GET_ALL_BUSINESSES",
      "GET_ALL_BANKS",
      "GET_ALL_TERMINAL_PROVIDERS",
    ]),
    send(x) {
      this.payload.business_id = x.business_id;
    },
    hide() {
      this.search = "";
      this.businessDropdown = false;
    },
    async updateLoan() {
      if (this.payload.business_id == "") {
        alert("Enter Business Id");
        return;
      }
      let res = await this.$store.dispatch("UPDATE_LOAN", this.payload);
      if (res.status) {
        this.$router.push({ name: "Terminals" });
      } else {
        alert(res.error);
      }
    },
  },
  async created() {
    // let res = await this.$store.dispatch("GET_SINGLE_LOAN", {
    //   id: this.$route.params.id,
    // });
    this.payload.terminal_id = this.$route.params.id.loan_item.terminal_id;
    this.payload.loan_amount =
      this.$route.params.id.loan_item.initial_loan_amount;
    this.payload.business_id = this.$route.params.id.business_id;
    this.payload.repayment_amount =
      this.$route.params.id.loan_item.loan_repayment_type.amount_or_percent_value;
    this.isLoading = true;

    let businesses = await this.$store.dispatch("GET_ALL_BUSINESSES");
    this.businesses = businesses.data.items;

    let bank = await this.$store.dispatch("GET_ALL_BANKS");
    this.banks = bank.data.items;
    let providers = await this.$store.dispatch("GET_ALL_TERMINAL_PROVIDERS");
    this.terminalProviders = providers.data.items;
  },
};
</script>

<style></style>
